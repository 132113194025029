.header {
  height: 170px;
  border-radius: 15px 15px 0px 0px;
  background-image: url(../../../assets/images/svg/confirmDelet.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.headerShowConfirm {
  height: 170px;
  border-radius: 15px 15px 0px 0px;
  background-image: url(../../../assets/images/svg/confirmSucess.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.textHtml {
  text-align: justify;
}

h1 {
  font-weight: 600;
}
.h2 {
  margin-top: 10px;
  font-size: 18px;
}
